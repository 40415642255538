<template>
  <el-dialog title="请选择"
             width="1024px"
             @closed="closed"
             :visible.sync="dialogVisible"
             :modal="true"
             :close-on-press-escape="false"
             :close-on-click-modal="false"
             :wrapperClosable="false"
             :append-to-body="true"
             :modal-append-to-body="true">
    <div class="padding-lr">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item>
          <el-select v-model="searchData.searchType"
                     placeholder="关键词类型"
                     style="width: 120px">
            <el-option
              v-for="item in searchKeyType"
              :disabled="item.disabled"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-input class=""
                    placeholder="搜索关键字"
                    prefix-icon="el-icon-search"
                    v-model="searchData.searchValue"
                    @keyup.enter.native="search"
                    style="width: 260px">
          </el-input>
        </el-form-item>

        <el-form-item>
          <el-select v-model="searchData.status"
                     placeholder="上架状态"
                     style="width: 120px">
            <el-option
              v-for="item in saleStatusArray"
              :disabled="item.disabled"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-cascader class="width-all"
                       ref="region"
                       v-model="classifyValue"
                       :options="[{id:'',name:'全部'}].concat(classifyList)"
                       :props="{checkStrictly: true,value:'id',label:'name'}"></el-cascader>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" round @click="search">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="tableData"
        height="400"
        border
        style="width: 100%;overflow-y: auto;">
        <el-table-column label="选择" width="50">
          <template slot-scope="scope">
            <el-radio :label="scope.$index" v-model="radioIndex">{{&nbsp;}}</el-radio>
          </template>
        </el-table-column>
        <el-table-column
          prop="id"
          label="商品编码"
          width="180">
        </el-table-column>
        <el-table-column
          label="封面图"
          width="100">
          <template slot-scope="scope">
            <img class="goods-cover" :src="scope.row.cover">
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="商品名称"
          width="340">
        </el-table-column>
        <el-table-column
          label="状态"
          width="100">
          <template slot-scope="scope">
            {{saleStatus[scope.row.status]}}
          </template>
        </el-table-column>
        <el-table-column
          prop="price"
          label="售价"
          width="80">
        </el-table-column>
        <el-table-column
          prop="onShelfTime_text"
          label="上架时间"
          width="100">
        </el-table-column>
      </el-table>
      <div class="paging-row">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 15, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalListNum">
        </el-pagination>
      </div>

    </div>
    <div class="text-center margin-tb">
      <el-button type="info" @click="cancel">取消</el-button>
      <el-button type="primary" @click="confirm">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>

import {
  goodsReviewStatusArray,
  saleStatus,
  saleStatusArray,
  isDeleteStatus,
  isDeleteStatusArray
} from '../../../assets/js/status'

import { URL } from '../../../config'

import { disposeSearchData, parseTimeArray } from '../../../assets/js/utils'

import { disposeTreeData } from '../../../assets/js/goodsClassify'

export default {
  name: 'selectStoreGoods',
  props: {
    value: Object,
    visible: Boolean,
    businessType: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      dialogVisible: false,
      hasInit: false,
      radioIndex: -1,
      loading: false,
      /* 数据表格 */
      saleStatus,
      saleStatusArray,
      isDeleteStatus,
      isDeleteStatusArray,
      goodsReviewStatusArray: goodsReviewStatusArray.filter(res => [1].includes(res.id)),
      searchKeyType: [
        {
          id: 'goodsId',
          name: '商品编码'
        },
        {
          id: 'goodsName',
          name: '商品名称'
        },
      ],
      classifyList: [],
      classifyValue: [''],
      searchData: {
        status: 0,
        searchType: 'goodsName',
        searchValue: '',
        firstCateId: '', // 一级分类ID
        secCateId: '', // 2级分类ID
        thirdCateId: '', // 3级分类ID
      },
      tableData: [], // 数据列表
      currentPage: 1, // 当前页码
      totalListNum: 0, // 列表总条量
      pageSize: 10, // 每页显示得条数
    }
  },
  created () {
    if (this.visible) {
      this.dialogVisible = this.visible
    }
  },
  methods: {
    cancel () {
      this.dialogVisible = false
    },
    closed () {
      this.$emit('closed', ...arguments)
    },
    confirm () {
      if (this.radioIndex < 0) {
        return this.$message.error('请选择一条数据')
      }
      const sg = this.tableData[this.radioIndex]
      const sourceObj = {
        id: sg.id,
        name: sg.name,
        img: sg.cover,
      }
      this.$emit('saveObj', sourceObj)
      this.cancel()
    },
    /**
       * 拼接搜索参数
       */
    jointSearchData () {
      let data = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        businessType: this.businessType
      }
      data = Object.assign(data, this.searchData)
      disposeSearchData(data)
      return data
    },
    /**
       * 搜索
       */
    search () {
      this.currentPage = 1
      this.getList()
    },
    /**
       * 查询列表数据
       */
    getList () {
      return new Promise((resolve, reject) => {
        const data = this.jointSearchData()
        this.loading = true
        this.axios.get(URL.supplierGoods.list, { params: data }).then(res => {
          parseTimeArray(res.data.records, ['updateTime', 'onShelfTime'])
          this.totalListNum = res.data.total
          this.tableData = res.data.records
          resolve(res)
        }).catch(res => {
          this.tableData = []
          // reject(res);
        }).finally(res => {
          this.loading = false
        })
      })
    },
    /**
       * pageSize 改变时会触发：选择每页显示条数
       */
    handleSizeChange (val, type) {
      this.pageSize = val
      this.currentPage = 1
      this.getList()
    },
    /**
       * currentPage 改变时会触发：选择页数
       */
    handleCurrentChange (val, type) {
      this.currentPage = val
      this.getList()
    },
    /**
       * 获取分类列表
       */
    getListClassify () {
      const data = {
        status: 1,
      }
      this.loading = true
      this.axios.get(URL.supplierGoods.getSupplierBrandCateList, { params: data }).then(res => {
        this.classifyList = disposeTreeData(res.data)
      }).catch(res => {
        this.classifyList = []
      }).finally(res => {
        this.loading = false
      })
    },
  },
  watch: {
    visible (newValue) {
      console.log('watch visible', newValue)
      this.dialogVisible = newValue
      if (newValue && !this.hasInit) {
        this.getListClassify()
        this.getList()
        this.hasInit = true
      }
    },
    dialogVisible (newValue) {
      console.log('watch dialogVisible', newValue)
      this.radioIndex = -1
      this.$emit('update:visible', newValue)
    },
    classifyValue: function (newValue) {
      const [firstCateId, secCateId, thirdCateId] = newValue
      this.searchData.firstCateId = firstCateId
      this.searchData.secCateId = secCateId
      this.searchData.thirdCateId = thirdCateId
      this.search()
    },
    tableData: function () {
      this.radioIndex = -1
    },
  }
}
</script>

<style lang="less" scoped>
  .goods-cover {
    width: 60px;
    height: 60px;
    object-fit: contain;
  }
</style>
